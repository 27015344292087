import './app.scss';
import domready from 'domready';
import 'lazysizes';
import { ResizeHandler } from './utilities/js/resize-handler';
import { EventScroller } from './utilities/js/event-scroller';
import { loadLazyImages } from './utilities/js/lazy-image-loader';
import { bindTrackingClick } from './utilities/js/tracking';

// GLOBAL OPTIONS
window.OPTIONS = { // eslint-disable-line
    breakpoints: {
        s: 320,
        m: 768,
        l: 1024,
        xl: 1280,
        xxl: 1440,
        xxxl: 1920
    },
    loader: '<b class="loading"><b class="loading__loader"><i>loading...</i></b></b>'
};

window.eventScroller = new EventScroller();
window.resizeHandler = new ResizeHandler();

// const $body = document.querySelector('body');

/**
 * define function for initialising modules
 */

window.initContent = ($content) => {
    console.log('start app');
    bindTrackingClick($content);

    loadLazyImages();

    window.checkInView = (entries, observer) => {
        console.log('in view');
        console.log(entries);
        entries.forEach((entry) => {
            console.log(entry);
            if (entry.isIntersecting) {
                if (!entry.target.classList.contains('inview')) {
                    entry.target.classList.add('inview');

                    if (typeof entry.target.dataset.footer === 'undefined') {
                        window.inViewObserver.unobserve(entry.target);
                    }
                }

                if (typeof entry.target.dataset.footer !== 'undefined') {
                    quickAccessSwitch('off');
                }
            } else {
                if (typeof entry.target.dataset.footer !== 'undefined') {
                    quickAccessSwitch('on');
                }
            }
        });
    };

    window.inViewObserver = new IntersectionObserver(window.checkInView, {
        threshold: 0.1
    });

    const $quickAccess = document.querySelector('[data-quick-access="root"]');
    const quickAccessSwitch = (state) => {
        if (!$quickAccess) {
            return;
        }

        if (state === 'off') {
            $quickAccess.classList.add('is--hidden');
        }
        if (state === 'on') {
            $quickAccess.classList.remove('is--hidden');
        }
    };

    const $$forms = $content.querySelectorAll('[data-form="root"]');
    if ($$forms.length > 0) {
        import('./components/form/form-handler').then(({ FormHandler }) => {
            for (let i = 0; i < $$forms.length; i++) {
                const $form = $$forms[i];
                const formAPI = new FormHandler($form);
                $form.API = formAPI;
            }
        });
    }

    // get items height and set it to longest value
    const $calcHeight = $content.querySelectorAll('[data-calc="root"]');
    if ($calcHeight.length > 0) {
        import('./components/calc-height/calc-height').then(({ CalcHeight }) => {
            for (let i = 0; i < $calcHeight.length; i++) {
                const calc = new CalcHeight($calcHeight[i]);
                calc.initialize();
            }
        });
    }

    // init modal component
    const $modal = $content.querySelectorAll('[data-modal]');
    if ($modal.length > 0) {
        import('./components/modal/modal').then(({ Modal }) => {
            for (let i = 0; i < $modal.length; i++) {
                const $modalContainer = new Modal($modal[i], {
                    loader: window.OPTIONS.loader,
                    onAjaxLoaded: ($content) => {
                        window.initContent($content);
                    }
                });

                $modalContainer.initialize();
            }
        });
    }

    // init toggle component
    const $$toggle = $content.querySelectorAll('[data-toggle]');
    if ($$toggle.length > 0) {
        import('./components/toggle/toggle').then(({ Toggle }) => {
            for (let i = 0; i < $$toggle.length; i++) {
                const $toggle = $$toggle[i];
                const toggleAPI = new Toggle($toggle);

                $toggle.API = toggleAPI;
            }
        });
    }

    // init precheck
    const $$precheck = $content.querySelectorAll('[data-precheck="root"]');
    if ($$precheck.length > 0) {
        import('./snippets/precheck/precheck').then(({ Precheck }) => {
            for (let i = 0; i < $$precheck.length; i++) {
                const $precheck = $$precheck[i];
                const precheckAPI = new Precheck($precheck, {
                    loader: window.OPTIONS.loader,
                    onSubmit: ($content) => {
                        window.initContent($content);
                    }
                });

                $precheck.API = precheckAPI;
            }
        });
    }

    // init scroll button
    const $scrollButton = $content.querySelectorAll('[data-scroll-button="root"]');
    if ($scrollButton.length > 0) {
        import('./components/scroll-to-top/scroll-to-top').then(({ ScrollToTop }) => {
            for (let i = 0; i < $scrollButton.length; i++) {
                const scrollButton = $scrollButton[i];
                scrollButton.API = new ScrollToTop(scrollButton);
            }
        });
    }

    // init add to cart
    const $$addToCart = $content.querySelectorAll('[data-add-to-cart="root"]');
    if ($$addToCart.length > 0) {
        import('./pages/cart/cart-add').then(({ CartAdd }) => {
            for (let i = 0; i < $$addToCart.length; i++) {
                const $addToCart = $$addToCart[i];
                $addToCart.API = new CartAdd($addToCart, {
                    loader: window.OPTIONS.loader,
                    onSubmit: ($content) => {
                        window.initContent($content);
                    }
                });
            }
        });
    }

    // init minicart
    const $minicart = $content.querySelectorAll('[data-minicart="root"]');
    if ($minicart.length > 0) {
        import('./layout/minicart/minicart').then(({ Minicart }) => {
            for (let i = 0; i < $minicart.length; i++) {
                $minicart[i].API = new Minicart($minicart[i]);
            }
        });
    }

    // init load products
    const $$productOverview = $content.querySelectorAll('[data-product-overview="root"]');
    if ($$productOverview.length > 0) {
        import('./pages/product/product-overview/product-overview').then(({ ProductOverview }) => {
            for (let i = 0; i < $$productOverview.length; i++) {
                const $productOverview = $$productOverview[i];
                const productOverviewAPI = new ProductOverview($productOverview, {
                    loader: window.OPTIONS.loader,
                    onSubmit: ($content) => {
                        window.initContent($content);
                    }
                });

                $productOverview.API = productOverviewAPI;
            }
        });
    }

    if (typeof window.Modernizr !== 'undefined') {
        if (!window.Modernizr.csshover) {
            const $hovers = $content.querySelectorAll('[data-hover]');
            const hoverClass = 'hover';

            if ($hovers.length > 0) {
                for (let i = 0; i < $hovers.length; i++) {
                    const $hover = $hovers[i];

                    $hover.addEventListener('touchstart', () => {
                        if (!$hover.classList.contains(hoverClass)) {
                            $hover.classList.add(hoverClass);
                        }
                    });
                }

                document.addEventListener('touchstart', (e) => {
                    const $hoveredElements = $content.getElementsByClassName(hoverClass);

                    for (let i = 0; i < $hoveredElements.length; i++) {
                        const $currentHover = $hoveredElements[i];
                        const isClickInside = $currentHover.contains(e.target);

                        if (!isClickInside) {
                            if ($currentHover.classList.contains(hoverClass)) {
                                $currentHover.classList.remove(hoverClass);
                            }
                        }
                    }
                });
            }
        }
    }
};

/**
 * starting point
 */
domready(function () {
    const initApplication = () => {
        const $navigation = document.querySelector('[data-nav="root"]');
        if ($navigation) {
            import('./layout/navigation/navigation').then(({ Navigation }) => {
                const navigationAPI = new Navigation($navigation);
                $navigation.API = navigationAPI;
            });
        }

        const $siteheader = document.querySelector('[data-siteheader="root"]');
        if ($siteheader) {
            import('./layout/siteheader/siteheader').then(({ SiteHeader }) => {
                const siteheaderAPI = new SiteHeader($siteheader, {
                    eventScroller: eventScroller, // eslint-disable-line
                    onCartLoaded: ($cart) => {
                        window.initContent($cart);
                    }
                });
                $siteheader.API = siteheaderAPI;
            });
        }

        const $$languages = document.querySelectorAll('[data-languages="root"]');
        if ($$languages.length > 0) {
            import('./layout/navigation/languages').then(({ Languages }) => {
                for (let i = 0; i < $$languages.length; i++) {
                    const $languages = $$languages[i];
                    const languagesAPI = new Languages($languages);
                    $languages.API = languagesAPI;
                }
            });
        }

        // init tooltip
        const $tooltip = document.querySelector('[data-tooltip="root"]');
        if ($tooltip) {
            import('./components/tooltip/tooltip').then(({ Tooltip }) => {
                new Tooltip($tooltip); // eslint-disable-line
            });
        }

        const $marketSwitch = document.querySelector('[data-marketswitch="root"]');
        if ($marketSwitch) {
            import('./layout/navigation/market-switch').then(({ MarketSwitch }) => {
                const marketSwitchAPI = new MarketSwitch($marketSwitch);
                $marketSwitch.API = marketSwitchAPI;
            });
        }

        // init sales banner
        const $salesBanner = document.querySelectorAll('[data-sales-banner="root"]');
        if ($salesBanner.length > 0) {
            import('./components/sales-banner/sales-banner').then(({ SalesBanner }) => {
                for (let i = 0; i < $salesBanner.length; i++) {
                    const salesBanner = $salesBanner[i];
                    salesBanner.API = new SalesBanner(salesBanner);
                }
            });
        }

        window.initContent(document);

        window.eventScroller.init();
        window.resizeHandler.init();

        const contentUpdateEvent = new CustomEvent('content.loaded', {
            view: window,
            bubbles: true,
            cancelable: false,
            detail: {
                $context: document
            }
        });

        window.dispatchEvent(contentUpdateEvent);

        window.dataInview = document.querySelectorAll('[data-inview]:not(.inview)');

        console.log(window.dataInview);
        setTimeout(() => {
            window.dataInview.forEach((dataInView) => {
                console.log(dataInView);
                window.inViewObserver.observe(dataInView);
            });
        }, 500);

        window.addEventListener('pageshow', (event) => {
            const historyTraversal = event.persisted || (typeof window.performance !== 'undefined' && window.performance.navigation.type === 2);
            if (historyTraversal) {
                window.location.reload();
            }
        });
    };

    initApplication();
});
