/**
 * usage
 * import { EventScroller } from './utilities/js/resize-handler';
 *
 * const eventScroller = new EventScroller();
 * eventScroller.init();
 *
 * eventScroller.customFunctions.push(() => { // do stuff });
 */

class EventScroller {
    constructor () {
        this.lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;
        this.customFunctions = [];
    }

    init () {
        this.bindEvents();
    }

    bindEvents () {
        let ticking = false;

        window.addEventListener('scroll', () => {
            if (!ticking) {
                window.requestAnimationFrame(() => {
                    for (let i = 0; i < this.customFunctions.length; i++) {
                        const customFunction = this.customFunctions[i];

                        if (typeof customFunction === 'function') {
                            customFunction();
                        }
                    }

                    ticking = false;
                });

                ticking = true;
            }
        });
    }

    // helper function, which returns current scroll position and scroll direction
    // https://stackoverflow.com/questions/31223341/detecting-scroll-direction
    getScrollInfo () {
        const position = window.pageYOffset || document.documentElement.scrollTop;
        let direction;

        if (position > this.lastScrollTop) {
            direction = 'down';
        } else {
            direction = 'up';
        }

        this.lastScrollTop = position <= 0 ? 0 : position; // For Mobile or negative scrolling

        return {
            position: position, // eslint-disable-line
            direction: direction // eslint-disable-line
        };
    }

    // helper function to check, if element is in view
    onScreen ($element, tolerance = 0, ignoreBefore = false) {
        const bounds = $element.getBoundingClientRect();

        if (ignoreBefore) {
            return (((bounds.top + tolerance) - window.innerHeight) <= 0 && bounds.bottom > 0);
        } else {
            if (bounds.top <= 0) {
                return true;
            } else {
                return (((bounds.top + tolerance) - window.innerHeight) <= 0 && bounds.bottom > 0);
            }
        }
    }
}

export { EventScroller };
