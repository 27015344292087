const loadLazyImages = () => {
    const pictures = document.querySelectorAll('.lazyload, .lazyloading, .lazyloaded');

    for (let i = 0; i < pictures.length; i++) {
        const picture = pictures[i];
        const image = picture.querySelector('img');

        if (image !== null) {
            image.classList.add('lazyload');
        }
    }
};

export { loadLazyImages };
